import { useState } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import useAuth from 'hooks/useAuth';
import IndexLayout from 'layouts/IndexLayout';
import MainLayout from 'layouts/MainLayout';
import AuthRoutes from 'components/AuthRoutes';
import IndexPage from 'pages/index/IndexPage';
import DepartamentosList from 'pages/departamentos/List';
import DepartamentosView from 'pages/departamentos/View';
import DepartamentosAdd from 'pages/departamentos/Add';
import DepartamentosEdit from 'pages/departamentos/Edit';
import EmpresasList from 'pages/empresas/List';
import EmpresasView from 'pages/empresas/View';
import EmpresasAdd from 'pages/empresas/Add';
import EmpresasEdit from 'pages/empresas/Edit';
import EstoqueList from 'pages/estoque/List';
import EstoqueEdit from 'pages/estoque/Edit';
import FuncionariosList from 'pages/funcionarios/List';
import FuncionariosView from 'pages/funcionarios/View';
import FuncionariosAdd from 'pages/funcionarios/Add';
import FuncionariosEdit from 'pages/funcionarios/Edit';
import FuncoesList from 'pages/funcoes/List';
import FuncoesView from 'pages/funcoes/View';
import FuncoesAdd from 'pages/funcoes/Add';
import FuncoesEdit from 'pages/funcoes/Edit';
import GruposList from 'pages/grupos/List';
import GruposAdd from 'pages/grupos/Add';
import GruposEdit from 'pages/grupos/Edit';
import PedidoitensList from 'pages/pedidoitens/List';
import PedidoitensAdd from 'pages/pedidoitens/Add';
import PedidoitensEdit from 'pages/pedidoitens/Edit';
import PedidosList from 'pages/pedidos/List';
import PedidosAdd from 'pages/pedidos/Add';
import PedidosEdit from 'pages/pedidos/Edit';
import PermissionsList from 'pages/permissions/List';
import PermissionsView from 'pages/permissions/View';
import PermissionsAdd from 'pages/permissions/Add';
import PermissionsEdit from 'pages/permissions/Edit';
import ProdutogruposList from 'pages/produtogrupos/List';
import ProdutogruposAdd from 'pages/produtogrupos/Add';
import ProdutogruposEdit from 'pages/produtogrupos/Edit';
import ProdutosList from 'pages/produtos/List';
import ProdutosAdd from 'pages/produtos/Add';
import ProdutosEdit from 'pages/produtos/Edit';
import RolesList from 'pages/roles/List';
import RolesView from 'pages/roles/View';
import RolesAdd from 'pages/roles/Add';
import RolesEdit from 'pages/roles/Edit';
import SetoresList from 'pages/setores/List';
import SetoresView from 'pages/setores/View';
import SetoresAdd from 'pages/setores/Add';
import SetoresEdit from 'pages/setores/Edit';
import UsersList from 'pages/users/List';
import UsersView from 'pages/users/View';
import UsersAdd from 'pages/users/Add';
import UsersEdit from 'pages/users/Edit';
import VfuncionariosList from 'pages/vfuncionarios/List';
import VpatrimonioList from 'pages/vpatrimonio/List';
import VprodList from 'pages/vprod/List';
import DashboardPage from 'pages/custom/DashboardPage';
import ApuracaoPage from 'pages/custom/ApuracaoPage';
import AccountPages from 'pages/account';
import HomePage from './pages/home/HomePage';
import IndexPages from './pages/index';
import ErrorPages from './pages/errors';
import 'primereact/resources/primereact.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import 'assets/styles/layout.scss';
import KardexResumePage from 'pages/custom/KardexPageResume';
import KardexDetailPage from 'pages/custom/KardexPageDetail';
import DetailProdutoGrupos from 'pages/custom/DetailProdutoGrupos';
import GrupoGestorProdGridPage from 'pages/grupogestor/ListGrupoProdGrid';

const App = () => {
	const auth = useAuth();
	const [empid, setempid] = useState(null);

	function DefaultPage() {
		if (!auth.isLoggedIn) {
			return <IndexPage />
			// return <LoginPage />
		}
		// return <Navigate to="/produtogrupos" replace />;
		// return <Navigate to="/DetailProdutoGrupos" replace />;
		return <Navigate to="/funcionarios" replace />;
	}
	return (
			<Routes>
				<Route exact element={<AuthRoutes />}>
					<Route element={<MainLayout />}>
						<Route path="/home" element={<GrupoGestorProdGridPage />} />
						{/* <Route path="/home" element={<ApuracaoPage />} /> */}
						<Route path="/GrupoGestorProdGridPage" element={<GrupoGestorProdGridPage />} />

						{/* departamentos pages routes */}
						<Route path="/departamentos" element={<DepartamentosList />} />
						<Route path="/departamentos/:fieldName/:fieldValue" element={<DepartamentosList />} />
						<Route path="/departamentos/index/:fieldName/:fieldValue" element={<DepartamentosList />} />
						<Route path="/departamentos/view/:pageid" element={<DepartamentosView />} />
						<Route path="/departamentos/add" element={<DepartamentosAdd />} />
						<Route path="/departamentos/edit/:pageid" element={<DepartamentosEdit />} />

						{/* empresas pages routes */}
						<Route path="/empresas" element={<EmpresasList />} />
						<Route path="/empresas/:fieldName/:fieldValue" element={<EmpresasList />} />
						<Route path="/empresas/index/:fieldName/:fieldValue" element={<EmpresasList />} />
						<Route path="/empresas/view/:pageid" element={<EmpresasView />} />
						<Route path="/empresas/add" element={<EmpresasAdd />} />
						<Route path="/empresas/edit/:pageid" element={<EmpresasEdit />} />

						{/* estoque pages routes */}
						<Route path="/estoque" element={<EstoqueList />} />
						<Route path="/estoque/:fieldName/:fieldValue" element={<EstoqueList />} />
						<Route path="/estoque/index/:fieldName/:fieldValue" element={<EstoqueList />} />
						<Route path="/estoque/edit/:pageid" element={<EstoqueEdit />} />

						{/* funcionarios pages routes */}
						<Route path="/funcionarios" element={<FuncionariosList />} />
						<Route path="/funcionarios/:fieldName/:fieldValue" element={<FuncionariosList />} />
						<Route path="/funcionarios/index/:fieldName/:fieldValue" element={<FuncionariosList />} />
						<Route path="/funcionarios/view/:pageid" element={<FuncionariosView />} />
						<Route path="/funcionarios/add" element={<FuncionariosAdd />} />
						<Route path="/funcionarios/edit/:pageid" element={<FuncionariosEdit />} />

						{/* funcoes pages routes */}
						<Route path="/funcoes" element={<FuncoesList />} />
						<Route path="/funcoes/:fieldName/:fieldValue" element={<FuncoesList />} />
						<Route path="/funcoes/index/:fieldName/:fieldValue" element={<FuncoesList />} />
						<Route path="/funcoes/view/:pageid" element={<FuncoesView />} />
						<Route path="/funcoes/add" element={<FuncoesAdd />} />
						<Route path="/funcoes/edit/:pageid" element={<FuncoesEdit />} />

						{/* grupos pages routes */}
						<Route path="/grupos" element={<GruposList />} />
						<Route path="/grupos/:fieldName/:fieldValue" element={<GruposList />} />
						<Route path="/grupos/index/:fieldName/:fieldValue" element={<GruposList />} />
						<Route path="/grupos/add" element={<GruposAdd />} />
						<Route path="/grupos/edit/:pageid" element={<GruposEdit />} />

						{/* pedidoitens pages routes */}
						<Route path="/pedidoitens" element={<PedidoitensList />} />
						<Route path="/pedidoitens/:fieldName/:fieldValue" element={<PedidoitensList />} />
						<Route path="/pedidoitens/index/:fieldName/:fieldValue" element={<PedidoitensList />} />
						<Route path="/pedidoitens/add" element={<PedidoitensAdd />} />
						<Route path="/pedidoitens/edit/:pageid" element={<PedidoitensEdit />} />

						{/* pedidos pages routes */}
						<Route path="/inventario" element={<PedidosList />} />
						<Route path="/pedidos/:fieldName/:fieldValue" element={<PedidosList />} />
						<Route path="/pedidos/index/:fieldName/:fieldValue" element={<PedidosList />} />
						<Route path="/pedidos/add" element={<PedidosAdd />} />
						<Route path="/pedidos/edit/:pageid" element={<PedidosEdit />} />

						{/* permissions pages routes */}
						<Route path="/permissions" element={<PermissionsList />} />
						<Route path="/permissions/:fieldName/:fieldValue" element={<PermissionsList />} />
						<Route path="/permissions/index/:fieldName/:fieldValue" element={<PermissionsList />} />
						<Route path="/permissions/view/:pageid" element={<PermissionsView />} />
						<Route path="/permissions/add" element={<PermissionsAdd />} />
						<Route path="/permissions/edit/:pageid" element={<PermissionsEdit />} />

						{/* produtogrupos pages routes */}
						<Route path="/produtogrupos" element={<ProdutogruposList />} />
						<Route path="/produtogrupos/:fieldName/:fieldValue" element={<ProdutogruposList />} />
						<Route path="/produtogrupos/index/:fieldName/:fieldValue" element={<ProdutogruposList />} />
						<Route path="/produtogrupos/add" element={<ProdutogruposAdd />} />
						<Route path="/produtogrupos/edit/:pageid" element={<ProdutogruposEdit />} />

						{/* produtos pages routes */}
						<Route path="/produtos" element={<ProdutosList />} />
						<Route path="/produtos/:fieldName/:fieldValue" element={<ProdutosList />} />
						<Route path="/produtos/index/:fieldName/:fieldValue" element={<ProdutosList />} />
						<Route path="/produtos/add" element={<ProdutosAdd />} />
						<Route path="/produtos/edit/:pageid" element={<ProdutosEdit />} />

						{/* roles pages routes */}
						<Route path="/roles" element={<RolesList />} />
						<Route path="/roles/:fieldName/:fieldValue" element={<RolesList />} />
						<Route path="/roles/index/:fieldName/:fieldValue" element={<RolesList />} />
						<Route path="/roles/view/:pageid" element={<RolesView />} />
						<Route path="/roles/add" element={<RolesAdd />} />
						<Route path="/roles/edit/:pageid" element={<RolesEdit />} />

						{/* setores pages routes */}
						<Route path="/setores" element={<SetoresList />} />
						<Route path="/setores/:fieldName/:fieldValue" element={<SetoresList />} />
						<Route path="/setores/index/:fieldName/:fieldValue" element={<SetoresList />} />
						<Route path="/setores/view/:pageid" element={<SetoresView />} />
						<Route path="/setores/add" element={<SetoresAdd />} />
						<Route path="/setores/edit/:pageid" element={<SetoresEdit />} />

						{/* users pages routes */}
						<Route path="/users" element={<UsersList />} />
						<Route path="/users/:fieldName/:fieldValue" element={<UsersList />} />
						<Route path="/users/index/:fieldName/:fieldValue" element={<UsersList />} />
						<Route path="/users/view/:pageid" element={<UsersView />} />
						<Route path="/users/add" element={<UsersAdd />} />
						<Route path="/users/edit/:pageid" element={<UsersEdit />} />

						{/* vfuncionarios pages routes */}
						<Route path="/vfuncionarios" element={<VfuncionariosList />} />
						<Route path="/vfuncionarios/:fieldName/:fieldValue" element={<VfuncionariosList />} />
						<Route path="/vfuncionarios/index/:fieldName/:fieldValue" element={<VfuncionariosList />} />

						{/* vpatrimonio pages routes */}
						<Route path="/vpatrimonio" element={<VpatrimonioList />} />
						<Route path="/vpatrimonio/:fieldName/:fieldValue" element={<VpatrimonioList />} />
						<Route path="/vpatrimonio/index/:fieldName/:fieldValue" element={<VpatrimonioList />} />

						{/* vprod pages routes */}
						<Route path="/vprod" element={<VprodList />} />
						<Route path="/vprod/:fieldName/:fieldValue" element={<VprodList />} />
						<Route path="/vprod/index/:fieldName/:fieldValue" element={<VprodList />} />
						<Route path="/kardex" element={<KardexResumePage />} />
						<Route path="/kardexdetail" element={<KardexDetailPage />} />
						<Route path="/dashboard" element={<DashboardPage />} />
						<Route path="/apuracao" element={<ApuracaoPage />} />
						<Route path="/account/*" element={<AccountPages />} />

						<Route path="/DetailProdutoGrupos/*" element={<DetailProdutoGrupos />} />


					</Route>
				</Route>
				<Route exact element={<IndexLayout />}>
					<Route path="/" element={<DefaultPage />} />
					<Route path="/*" element={<IndexPages />} />
					<Route path="/error/*" element={<ErrorPages />} />
				</Route>
			</Routes>
	);
}
export default App;
