import React, { useState, useEffect } from "react";
import { MultiSelect } from "primereact/multiselect";
import useApi from 'hooks/useApi';

const MultiSelectGroup = ({ CurrentData = [], onSelectChange, endpoint = null, fields = [] }) => {
    const api = useApi();

    const [GroupList, setGroupList] = useState([]);
    const [selectedGroups, setSelectedGroups] = useState([]);
    const _label = fields.label ? fields.label : "label";
    const _value = fields.value ? fields.value : "value";

    // Load List API
    useEffect(() => {
        // Retorna com os Grupos
        const fetchDataGrupsList = async () => {
            var _endpoint = endpoint ? endpoint : 'components_data/grupoid_option_list_2';  
            const response = await api.get(_endpoint);
            setGroupList(response.data);
        };
        fetchDataGrupsList();
        setSelectedGroups(CurrentData);
    }
    , []);

    const handleOnChange = (e) => {
        setSelectedGroups(e.value);
        onSelectChange(e);
        console.log("SELECIONADO");
    };

    return (
        <MultiSelect
            options={GroupList}
            value={selectedGroups}
            onChange={handleOnChange}
            optionLabel={_label}
            optionValue={_value}
            filter
            placeholder="Selecione os Grupos"
            //maxSelectedLabels={3}
            display="chip"
            style={{ width: "100%" }}
        />
    );
};

export default MultiSelectGroup;
