import { useEffect, useState } from "react";
import { BreadCrumb } from "primereact/breadcrumb";
import { Formik, Form, ErrorMessage } from "formik";
import { Button } from "primereact/button";
import { CanView } from "components/Can";
import { CellEditor } from "components/CellEditor";
import { Column } from "primereact/column";
import { DataSource } from "components/DataSource";
import { DataTable } from "primereact/datatable";
import { ExportPageData } from "components/ExportPageData";
import { FilterTags } from "components/FilterTags";
import { InputText } from "primereact/inputtext";
import { PageRequestError } from "components/PageRequestError";
import { Paginator } from "primereact/paginator";
import { ProgressSpinner } from "primereact/progressspinner";
import { SplitButton } from "primereact/splitbutton";
import { Title } from "components/Title";
import { Dropdown } from "primereact/dropdown";
import ProdutogruposAddPage from "pages/produtogrupos/Add";
import ProdutogruposEditPage from "pages/produtogrupos/Edit";
import useApi from "hooks/useApi";
import useApp from "hooks/useApp";
import useAuth from "hooks/useAuth";
import useUtils from "hooks/useUtils";


import useListPage from "hooks/useListPage";
const ProdutogruposListPage = (props) => {
    const auth = useAuth();
    const app = useApp();
    const api = useApi();
    const utils = useUtils();
    const { empid } = useApp();
    const [GrupoId, setGrupoId] = useState();
	const [products, setProducts] = useState([]);

    const filterSchema = {
        search: {
            tagTitle: "Search",
            value: "",
            valueType: "single",
            options: [],
        },
    };

	useEffect(() => {
		const fetchProducts = async () => {
			try {
			  const response = await fetch('fakedata/products.json');
			  const data = await response.json();
			  setProducts(data);
			} catch (error) {
			  console.error('Erro ao carregar os produtos:', error);
			}
		  };
	  
		  fetchProducts();		
	}, []);


    const pageController = useListPage(props, filterSchema);
    const filterController = pageController.filterController;
    const {
        records,
        pageReady,
        loading,
        selectedItems,
        apiUrl,
        sortBy,
        sortOrder,
        apiRequestError,
        setSelectedItems,
        getPageBreadCrumbs,
        onSort,
        deleteItem,
        pagination,
    } = pageController;
    const { filters, setFilterValue } = filterController;
    const {
        totalRecords,
        totalPages,
        recordsPosition,
        firstRow,
        limit,
        onPageChange,
    } = pagination;

    function ActionButton(data) {
        const items = [
            {
                label: "Editar",
                command: (event) => {
                    app.openPageDialog(
                        <ProdutogruposEditPage
                            isSubPage
                            apiPath={`/produtogrupos/edit/${data.id}`}
                        />,
                        { closeBtn: true }
                    );
                },
                icon: "pi pi-pencil",
                visible: () => auth.canView("produtogrupos/edit"),
            },
            {
                label: "Excluir",
                command: (event) => {
                    deleteItem(data.id);
                },
                icon: "pi pi-trash",
                visible: () => auth.canView("produtogrupos/delete"),
            },
        ].filter((item) => {
            if (item.visible) {
                return item.visible();
            }
            return true;
        });
        return (
            <SplitButton
                dropdownIcon="pi pi-bars"
                className="dropdown-only p-button-text p-button-plain"
                model={items}
            />
        );
    }
    async function onCellEditComplete(e) {
        const { rowData: data, newValue, field, originalEvent: event } = e;
        if (newValue) {
            try {
                const payload = {
                    [field]: newValue,
                };
                data[field] = newValue;
                const url = `produtogrupos/edit/${data.id}`;
                await api.post(url, payload);
            } catch (err) {
                const msg =
                    err?.request?.response || "Unabled to completed request";
                app.flashMsg("Update Field", msg, "error");
                console.error(err);
            }
        } else {
            event.preventDefault();
        }
    }
    function IdTemplate(data) {
        if (data) {
            return <>{data.id}</>;
        }
    }
    function NomeTemplate(data) {
        if (data) {
            return (
                <span className="table-inlined-edit">{data.nome}</span>
            );
        }
    }
    function PageLoading() {
        if (loading) {
            return (
                <>
                    <div className="flex align-items-center justify-content-center text-gray-500 p-3">
                        <div>
                            <ProgressSpinner
                                style={{ width: "50px", height: "50px" }}
                            />{" "}
                        </div>
                        <div className="font-bold text-lg">Carregando...</div>
                    </div>
                </>
            );
        }
    }
    function EmptyRecordMessage() {
        if (pageReady && !records.length) {
            return (
                <div className="text-lg mt-3 p-3 text-center text-400 font-bold">
                    Nenhum Registro Encontrado
                </div>
            );
        }
    }
    function MultiDelete() {
        if (selectedItems.length) {
            return (
                <div className="m-2 flex-grow-0">
                    <Button
                        onClick={() => deleteItem(selectedItems)}
                        icon="pi pi-trash"
                        className="p-button-danger"
                        title="Excluir selecionado"
                    />
                </div>
            );
        }
    }
    function ExportData() {
        if (props.exportData && records.length) {
            const downloadFileName = `${utils.dateNow()}-produtogrupos`;
            return (
                <div className="m-2">
                    <ExportPageData
                        csv={false}
                        pageUrl={apiUrl}
                        downloadFileName={downloadFileName}
                        butonLabel="Exportar"
                        tooltip="Exportar"
                        buttonIcon="pi pi-print"
                    />
                </div>
            );
        }
    }
    function PagerControl() {
        if (props.paginate && totalPages > 1) {
            const pagerReportTemplate = {
                layout: pagination.layout,
                CurrentPageReport: (options) => {
                    return (
                        <>
                            <span className="text-sm text-gray-500 px-2">
                                Registros{" "}
                                <b>
                                    {recordsPosition} do {options.totalRecords}
                                </b>
                            </span>
                        </>
                    );
                },
            };
            return (
                <div className="flex-grow-1">
                    <Paginator
                        first={firstRow}
                        rows={limit}
                        totalRecords={totalRecords}
                        onPageChange={onPageChange}
                        template={pagerReportTemplate}
                    />
                </div>
            );
        }
    }
    function PageActionButtons() {
        return (
            <div className="flex flex-wrap">
                <CanView pagePath="produtogrupos/delete">
                    <MultiDelete />
                </CanView>
                <ExportData />
            </div>
        );
    }
    function PageFooter() {
        if (pageReady && props.showFooter) {
            return (
                <div className="flex flex-wrap">
                    <PageActionButtons />
                    <PagerControl />
                </div>
            );
        }
    }
    function PageBreadcrumbs() {
        if (props.showBreadcrumbs) {
            const items = getPageBreadCrumbs();
            return (
                items.length > 0 && (
                    <BreadCrumb className="mb-3" model={items} />
                )
            );
        }
    }
    if (apiRequestError) {
        return <PageRequestError error={apiRequestError} />;
    }
    return (
        <main id="ProdutogruposListPage" className="main-page">
            {props.showHeader && (
                <section className="page-section mb-3">
                    <div className="container-fluid">
                        <div className="grid justify-content-between align-items-center">
                            <div className="col ">
                                <Title
                                    title="Grupo de Produtos"
                                    titleClass="text-2xl text-primary font-bold"
                                    subTitleClass="text-500"
                                    separator={false}
                                />
                            </div>
                        </div>
                    </div>
                </section>
            )}

            <section className="page-section">
                <div className="container-fluid">
                    <div className="col comp-grid">
                        <div className='flexboxcontainer fill'>
                            <div className="md:col-8 col-8">
                                <DataSource apiPath="components_data/grupoid_option_list_2">
                                    {({ response }) => (
                                        <>
                                            <Dropdown style={{width:"100%"}}
                                                name="grupoid"
                                                optionLabel="label"
                                                optionValue="value"
                                                value={GrupoId}
                                                onChange={(e) =>
                                                    setGrupoId(e.value)
                                                }
                                                options={response}
                                                label="Grupo"
                                                placeholder="Selecione um valor ..."
                                                // className={inputClassName(formik?.errors?.grupoid)}
                                            />
                                            {/* <ErrorMessage name="grupoid" component="span" className="p-error" /> */}
                                        </>
                                    )}
                                </DataSource>
                            </div>
                            <div className="col-2">
                                <CanView pagePath="produtogrupos/add">
                                    <Button
                                        label="Adicionar Produto"
                                        icon="pi pi-plus"
                                        onClick={() =>
                                            app.openPageDialog(
                                                <ProdutogruposAddPage
                                                    isSubPage
													grupoid={GrupoId}
                                                    apiPath={`/produtogrupos/addmulti`}
                                                />,
                                                { closeBtn: true }
                                            )
                                        }
                                        className="p-button w-full bg-primary"
                                    />
                                </CanView>
                            </div>
                        </div>

                        <div className="col-12 md:col-12 ">
                            <span className="p-input-icon-left w-full">
                                <i className="pi pi-search" />
                                <InputText
                                    placeholder="Pesquisa"
                                    className="w-full"
                                    value={filters.search.value}
                                    onChange={(e) =>
                                        setFilterValue("search", e.target.value)
                                    }
                                />
                            </span>
                        </div>
                    </div>

                    <div className="grid ">
                        <div className="col comp-grid">
                            <FilterTags filterController={filterController} />
                            <div>
                                <PageBreadcrumbs />
                                <div className="page-records">
                                    <DataTable
                                        lazy={true}
                                        loading={loading}
                                        editMode="row"
                                        selectionMode="checkbox"
                                        selection={selectedItems}
                                        onSelectionChange={(e) => setSelectedItems(e.value) }
                                        value={products}
                                        dataKey="id"
                                        sortField={sortBy}
                                        sortOrder={sortOrder}
                                        onSort={onSort}
                                        className="editable-cells-table p-datatable-sm"
                                        stripedRows={true}
                                        showGridlines={false}
                                        rowHover={true}
                                        responsiveLayout="stack"
                                        emptyMessage={<EmptyRecordMessage />}
                                    >
                                        {/*PageComponentStart*/}
                                        {/* <Column selectionMode="multiple" headerStyle={{ width: '2rem' }}></Column> */}
                                        <Column
                                            field="id"
                                            header="Código"
                                            headerStyle={{ width: "10%" }}
                                            body={IdTemplate}
                                            sortable={true}
                                        ></Column>
                                        <Column
                                            field="nome"
                                            header="Produto"
                                            body={NomeTemplate}
                                            sortable={true}
                                            onCellEditComplete={ onCellEditComplete }
                                        ></Column>
										<Column rowEditor={true} headerStyle={{ width: '10%', minWidth: '8rem' }} bodyStyle={{ textAlign: 'center' }}></Column>
                                    </DataTable>
                                </div>
                                <PageFooter />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
};
ProdutogruposListPage.defaultProps = {
    primaryKey: "id",
    pageName: "produtogrupos",
    apiPath: "produtogrupos/index",
    routeName: "produtogruposlist",
    msgBeforeDelete: "Tem certeza de que deseja excluir este registro?",
    msgTitle: "Apagar registro",
    msgAfterDelete: "Registro excluído com sucesso",
    showHeader: true,
    showFooter: true,
    paginate: true,
    isSubPage: false,
    showBreadcrumbs: true,
    exportData: true,
    importData: false,
    keepRecords: false,
    multiCheckbox: true,
    search: "",
    fieldName: null,
    fieldValue: null,
    sortField: "",
    sortDir: "",
    pageNo: 1,
    limit: 10,
};
export default ProdutogruposListPage;
