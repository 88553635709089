import React, { useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Tag } from "primereact/tag";
import useApi from "hooks/useApi";
import { Button } from "primereact/button";

export default function GrupoGestorProdGridPage() {
    const api = useApi();

    const [SelectedGroup, setSelectedGroup] = useState(null);
    const [GroupList, setGroupList] = useState(null);
    const [GroupGestorList, setGroupGestorList] = useState(null);

    const [ProdutosGrid, setProdutosGrid] = useState([]);
    const [ProdutosList, setProdutosList] = useState([]);
    const [EditingLine, setEditingLine] = useState(false);
    const [EditingRows, setEditingRows] = useState({});

    useEffect(() => {
        const fetchProdutos = async () => {
            try {
                // const response = await api.get(`produtos?search=${Query}`);
                const response = await api.get(`produtos?search=`);
                setProdutosList(response.data.records);
            } catch (error) {
                console.error("Erro ao buscar produtos:", error);
            }
        };
        fetchProdutos();
    }, []);

    useEffect(() => {
        // Retorna com os Grupos
        const fetchDataGrupsList = async () => {
            const response = await api.get("GrupoGestor/list");
            setGroupList(response.data);
        };
        fetchDataGrupsList();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const ComboListQtde = (rowdata) => {
        const qtdList = Array.from({ length: 10 }, (v, k) => k + 1);
        return (
            <Dropdown
                value={rowdata.value}
                options={qtdList}
                onChange={(e) => rowdata.editorCallback(e.value)}
                placeholder="Selecione"
            />
        );
    };

    // Qtde Integer
    const qtdeEditor = (options) => {
        return (
            <InputText
                value={options.value}
                onChange={(e) => options.editorCallback(e.target.value)}
            />
        );
    };

    const statusBodyTemplate = (rowData) => {
        return (
            <Tag
                value={rowData.inventoryStatus}
                severity={getSeverity(rowData.inventoryStatus)}
            ></Tag>
        );
    };

    const buttonDelete = (e) => {
        return <Button icon="pi pi-trash" rounded outlined />;
    };

    const allowEdit = (rowData) => {
        //setEditingLine(rowData.name !== "Blue Band");
        return EditingLine;
    };

    const HandleGroupGestorGridList = async (e) => {
        setSelectedGroup(e.value);
        // Lista os Itens do Grupo
        const response = await api.get(`grupogestor/list/grid/${e.value}`);
        setGroupGestorList(response.data);
        // Lista os Produtos do Grupos
        const responseProdGrid = await api.get(
            `grupogestor/list/grid/${e.value}/produtos`
        );
        setProdutosGrid(responseProdGrid.data);
    };

    // OnClick Button event
    const OnClickAddNewRow = () => {
        const newProd = {
            id: 0,
            prodid: null,
            prodNome: "CLICK AO LADO PARA EDITAR ------------------------>",
            qtde: 1,
            periodic: 90,
        };
        const newProds = [...GroupGestorList, newProd];
        setGroupGestorList(newProds);
    };

    // Api List Data from Combo
    const EditorProdutos = (row) => {
        if (row.rowData.prodid == null) {
            return (
                <Dropdown
                    filter
                    value={row.value}
                    options={ProdutosList}
                    optionLabel="nome"
                    optionValue="id"
                    onChange={(e) => row.editorCallback(e.value)}
                    placeholder="Selecione um produto"
                />
            );

        } else {
            return row.rowData.prodNome;
        }
    };

    const onRowEditComplete = (e) => {
        let { newData, index } = e;
        newData = {
            ...newData,
            prodNome: ProdutosList.find((x) => x.id === newData.prodid).nome,
        };
        let _GroupGestorList = [...GroupGestorList]; // Cria uma cópia
        _GroupGestorList[index] = newData; // Modifica a cópia
        setGroupGestorList(_GroupGestorList); // Atualiza o estado
    };

    const RowEditSave = async (e) => {
        let postData = {"grupoid":  e.data.grupoid, "prodid": e.data.prodid, "qtde": e.data.qtde, "periodic": e.data.periodic}; 
        try {
            let response = await api.post("grupogestor/add/produto", postData);
            console.log("Salvando:", response);
        } catch (e)
        {
            console.error("Erro ao salvar:", e);
        }
    };

    return (
        <div className="card p-fluid">
            <div className="grid">
                <div className="col-8">
                    <Dropdown
                        value={SelectedGroup}
                        onChange={HandleGroupGestorGridList}
                        options={GroupList}
                        optionValue="id"
                        optionLabel="nome"
                        placeholder="Grupo Gestor"
                        className="w-full md:w-14rem"
                    />
                </div>
            </div>
            <DataTable
                value={GroupGestorList}
                editMode="row"
                dataKey="id"
                onRowEditComplete={onRowEditComplete}
                tableStyle={{ minWidth: "50rem" }}
                
                editingRows={EditingRows} // Edita a linha
                // onRowEditChange={(e) => setEditingRows(e.data)}

                onRowEditSave={RowEditSave}
            >
                {/* <Column field="code" header="Produto" editor={(options) => textEditor(options)} style={{ width: '20%' }}></Column> */}
                <Column
                    header="Produto"
                    field="prodid"
                    body={(rowData) => rowData.prodNome}
                    editor={(e) => EditorProdutos(e)}
                    style={{ width: "70%" }}
                ></Column>

                <Column
                    field="qtde"
                    header="Quantidade"
                    editor={(options) => ComboListQtde(options)}
                    style={{ width: "10%" }}
                ></Column>

                <Column
                    field="periodic"
                    header="Periodicidade"
                    editor={(options) => qtdeEditor(options)}
                    style={{ width: "10%" }}
                ></Column>

                <Column
                    body={(rowData, options) => {
                        if (EditingRows == null)
                        {
                            //console.log("NULO");
                        }
                        else 
                        {
                            let data = EditingRows[0];
                            //console.log("NAO NULO", EditingRows);
                        }
                        // if (EditingRows[options.rowIndex]) {
                        //     return (
                        //         <Button
                        //             label="Salvar"
                        //             severity="success"
                        //             onClick={() => {
                        //                 setEditingLine(false);
                        //             }}
                        //         />
                        //     );
                        // }   
                    }}                
                ></Column>

                <Column
                    rowEditor={allowEdit}
                    headerStyle={{ width: "10%", minWidth: "8rem" }}
                    bodyStyle={{ textAlign: "center" }}
                ></Column>
                
            </DataTable>

            <div className="grid">
                <div className="col-2" style={{ marginLeft: "1rem" }}>
                    <Button
                        label="Adicionar Novo"
                        severity="warning"
                        onClick={OnClickAddNewRow}
                        // style={{ display: EditingLine ? "block" : "none" }}
                    />
                </div>
            </div>
        </div>
    );
}
