import React, { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import useApi from 'hooks/useApi';


const API_URL = 'YOUR_API_ENDPOINT'; // Replace with your actual API endpoint

function DetailProdutoGrupos() {
    const [items, setItems] = useState([]);
    const [newItem, setNewItem] = useState('');
    const [editingItem, setEditingItem] = useState(null);
    const [checkedItems, setCheckedItems] = useState([]);
    const api = useApi();

    useEffect(() => {
        fetchItems();
    }, []);

    const fetchItems = async () => {
        try {
            const response = await fetch(API_URL);
            const data = await response.json();
            setItems(data);
        } catch (error) {
            console.error('Error fetching items:', error);
        }
    };

    const handleAddItem = async () => {
        try {
            const response = await fetch(API_URL, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ name: newItem }),
            });
            const data = await response.json();
            setItems([...items, data]);
            setNewItem('');
        } catch (error) {
            console.error('Error adding item:', error);
        }
    };

    const handleEditItem = (item) => {
        setEditingItem(item);
        setNewItem(item.name);
    };



    const handleSaveEdit = async (itemId) => {
        try {
            const response = await fetch(`${API_URL}/${itemId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ name: newItem }),
            });
            if (response.ok) {
                const updatedItems = items.map((item) =>
                    item.id === itemId ? { ...item, name: newItem } : item
                );
                setItems(updatedItems);
                setEditingItem(null);
                setNewItem('');
            } else {
                console.error('Error updating item');
            }
        } catch (error) {
            console.error('Error updating item:', error);
        }
    };


    const handleDeleteItem = async (itemId) => {
        try {
            const response = await fetch(`${API_URL}/${itemId}`, {
                method: 'DELETE',
            });
            if (response.ok) {
                const updatedItems = items.filter(item => item.id !== itemId);
                setItems(updatedItems);
            } else {
                console.error('Error deleting item');
            }
        } catch (error) {
            console.error('Error deleting item:', error);
        }
    };

    const handleCheckChange = (itemId) => {
        setCheckedItems(prevChecked => {
            if (prevChecked.includes(itemId)) {
                return prevChecked.filter(id => id !== itemId);
            } else {
                return [...prevChecked, itemId];
            }
        });
    };





    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => handleEditItem(rowData)} />
                <Button icon="pi pi-trash" className="p-button-rounded p-button-danger" onClick={() => handleDeleteItem(rowData.id)} />

            </div>

        )
    }


    return (
        <div>
            <h1>Lista de Grupos de Produtos</h1>
            <div className="p-inputgroup">

                <InputText
                    type="text"
                    value={newItem}
                    onChange={(e) => setNewItem(e.target.value)}
                    placeholder="Adicionar item"
                />
                {editingItem ? (
                    <Button onClick={() => handleSaveEdit(editingItem.id)}>Salvar Edição</Button>
                ) : (
                    <Button onClick={handleAddItem}>Adicionar</Button>
                )}


            </div>



            <DataTable value={items} selection={checkedItems} onSelectionChange={e => setCheckedItems(e.value)} dataKey="id">

                <Column selectionMode="multiple" headerStyle={{ width: '3em' }}></Column>
                <Column field="name" header="Nome do Grupo"></Column>
                <Column body={actionBodyTemplate}></Column>

            </DataTable>



        </div>
    );
}

export default DetailProdutoGrupos;